@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Clicker+Script&family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");

html {
  font-size: 1vw;
  scroll-behavior: smooth;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
}

body {
  font-family: "Poppins" sans-serif;
  font-size: 1.2rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
section {
  scroll-behavior: smooth;
}

.logo span {
  font-family: "clicker Script", cursive;
}
.home-info h3 span {
  font-family: "clicker Script", cursive;
}
.active-section {
  color: #ec1839;
}
/* .card-animation:hover {
  transform: perspective(400px) rotateX(6deg) translateY(-10px);
  transform-origin: bottom;
  transition: all 0.5s;
} */

@media screen and (max-width: 500px) {
  html {
    font-size: 3.4vw;
  }
}
